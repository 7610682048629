<template>
  <div class="wave-svg" :style="`width:${width}px;height:${height}px;`">
    <div style="width: 100%; height: 100%">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        :width="width"
        :height="height"
        :viewbox="`0 0 ${width} ${height}`"
      >
        <defs>
          <linearGradient id="line1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style="stop-color: #ffd7af; stop-opacity: 1" />
            <stop offset="100%" style="stop-color: #ffad68; stop-opacity: 1" />
          </linearGradient>
        </defs>
        <defs>
          <linearGradient id="line2" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style="stop-color: #fff4e8; stop-opacity: 1" />
            <stop offset="100%" style="stop-color: #ffd7af; stop-opacity: 1" />
          </linearGradient>
        </defs>
        <defs>
          <linearGradient id="line3" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style="stop-color: #ffaf64; stop-opacity: 1" />
            <stop offset="100%" style="stop-color: #ffe9d3; stop-opacity: 1" />
          </linearGradient>
        </defs>
        <path
          v-if="topLinePath"
          :d="topLinePath"
          stroke="transparent"
          fill="url(#line3)"
        >
          <animateTransform
            attributeType="XML"
            attributeName="transform"
            begin="0s"
            :dur="`${speedTop}s`"
            type="translate"
            from="0 0"
            :to="`${bulgeDistance * 4} 0`"
            repeatCount="indefinite"
          />
        </path>
        <path
          v-if="centerLinePath"
          :d="centerLinePath"
          stroke="transparent"
          fill="url(#line2)"
        >
          <animateTransform
            attributeType="XML"
            attributeName="transform"
            begin="0s"
            :dur="`${speedCenter}s`"
            type="translate"
            from="0 0"
            :to="`${bulgeDistance * 4} 0`"
            repeatCount="indefinite"
          />
        </path>
        <path
          v-if="bottomLinePath"
          :d="bottomLinePath"
          stroke="transparent"
          fill="url(#line1)"
        >
          <animateTransform
            attributeType="XML"
            attributeName="transform"
            begin="0s"
            :dur="`${speedBottom}s`"
            type="translate"
            from="0 0"
            :to="`${bulgeDistance * 4} 0`"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: "svgWave",
  props: {
    width: {
      type: Number, //图像宽度
      default: 450,
    },
    height: {
      type: Number, //图像高度
      default: 400,
    },
    waterHeight: {
      type: Number, //水面高度
      default: 360,
    },
    bulgeHeight: {
      type: Number, //波浪起伏高度
      default: 15,
    },
    bulgeDistance: {
      type: Number, //波浪起伏间隔
      default: 30,
    },
    lineDistance: {
      type: Number, //三条线的距离
      default: 20,
    },
    speedTop: {
      type: Number, //第一根线速度
      default: 1,
    },
    speedCenter: {
      type: Number, //第一根线速度
      default: 1,
    },
    speedBottom: {
      type: Number, //第一根线速度
      default: 1,
    },
  },
  data() {
    return {
      topLinePath: null,
      centerLinePath: null,
      bottomLinePath: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let num = Math.floor(this.width / this.bulgeDistance);
      let basicPoint = this.height - this.waterHeight;
      let basicPointCenter = this.height - this.waterHeight + this.lineDistance;
      let basicPointBottom =
        this.height - this.waterHeight + this.lineDistance * 2;
      let topLinePath = `M ${-4 * this.bulgeDistance} ${basicPoint} Q ${
        -3 * this.bulgeDistance
      } ${basicPoint - this.bulgeHeight} ${
        -2 * this.bulgeDistance
      } ${basicPoint} T 0 ${basicPoint}`;
      for (let i = 1; i < num; i++) {
        topLinePath += ` T ${this.bulgeDistance * i * 2} ${basicPoint} `;
      }
      topLinePath += `v ${this.height} h -${3 * num * this.bulgeDistance} z`;
      let centerLinePath = `M ${
        -5 * this.bulgeDistance
      } ${basicPointCenter} Q ${-4 * this.bulgeDistance} ${
        basicPointCenter - this.bulgeHeight
      } ${-3 * this.bulgeDistance} ${basicPointCenter} T ${-this
        .bulgeDistance} ${basicPointCenter}`;
      for (let i = 1; i < num; i++) {
        centerLinePath += ` T ${
          -this.bulgeDistance + this.bulgeDistance * i * 2
        } ${basicPointCenter} `;
      }
      centerLinePath += `v ${this.height} h -${3 * num * this.bulgeDistance} z`;
      let bottomLinePath = `M ${
        -4 * this.bulgeDistance
      } ${basicPointBottom} Q ${-3 * this.bulgeDistance} ${
        basicPointBottom - this.bulgeHeight
      } ${-2 * this.bulgeDistance} ${basicPointBottom} T 0 ${basicPointBottom}`;
      for (let i = 1; i < num; i++) {
        bottomLinePath += ` T ${
          this.bulgeDistance * i * 2
        } ${basicPointBottom} `;
      }
      bottomLinePath += `v ${this.height} h -${3 * num * this.bulgeDistance} z`;
      this.topLinePath = topLinePath;
      this.centerLinePath = centerLinePath;
      this.bottomLinePath = bottomLinePath;
    },
  },
  watch: {
    waterHeight() {
      this.init();
    },
  },
};
</script>
<style lang="scss">
.wave-svg {
  width: 100%;
  height: 100%;
}
</style>
